import React from 'react';

const FillYourDetails = ({ className, fill }) => {
  return (
    <svg 
    className={className} 
    version="1.0" 
    xmlns="http://www.w3.org/2000/svg"
    width="200.000000pt" 
    height="200.000000pt" 
    viewBox="0 0 200.000000 200.000000"
    preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
 stroke="none">
<path d="M631 1664 l-31 -26 0 -563 0 -564 24 -28 c24 -28 25 -28 160 -31
l135 -4 -5 39 c-10 65 -6 63 -108 63 -84 0 -97 2 -106 19 -6 13 -10 191 -10
510 l0 490 22 15 c19 13 58 16 228 16 246 0 250 -1 250 -85 0 -99 35 -135 134
-135 81 0 86 -11 86 -187 l0 -143 28 -5 c68 -15 62 -32 62 170 0 104 -5 196
-10 211 -14 37 -204 229 -245 249 -27 12 -81 15 -308 15 l-275 0 -31 -26z"/>
<path d="M845 1360 c-15 -17 -16 -24 -7 -45 11 -24 15 -25 101 -25 79 0 92 2
101 19 27 51 -1 71 -99 71 -65 0 -81 -3 -96 -20z"/>
<path d="M845 1170 c-15 -17 -16 -24 -7 -45 l12 -25 218 0 c156 0 221 3 230
12 17 17 15 44 -4 62 -13 14 -48 16 -223 16 -195 0 -209 -1 -226 -20z"/>
<path d="M845 976 c-33 -50 6 -76 116 -76 66 0 72 2 122 41 29 23 54 45 55 50
2 5 -59 9 -137 9 -137 0 -141 -1 -156 -24z"/>
<path d="M1275 937 c-109 -37 -196 -113 -243 -215 -23 -48 -27 -70 -26 -147 0
-108 24 -180 84 -248 184 -209 510 -167 632 81 30 61 33 74 33 162 0 86 -3
101 -31 160 -38 80 -99 142 -179 183 -50 26 -73 31 -145 34 -52 2 -100 -2
-125 -10z m227 -109 c141 -60 204 -244 132 -384 -33 -65 -101 -124 -164 -143
-146 -44 -305 33 -357 172 -16 43 -15 154 1 200 55 152 231 223 388 155z"/>
<path d="M1418 593 l-97 -97 -55 52 c-46 43 -58 50 -77 44 -12 -5 -26 -19 -31
-31 -7 -20 2 -34 69 -102 44 -46 85 -79 96 -79 12 0 68 48 138 118 121 119
138 149 107 180 -27 27 -54 11 -150 -85z"/>
<path d="M846 788 c-9 -12 -16 -26 -16 -30 0 -17 42 -48 65 -48 20 0 30 10 50
50 l25 50 -54 0 c-45 0 -58 -4 -70 -22z"/>
</g>
</svg>
   
  )
}
export default FillYourDetails;