import React from "react";

const Rupee = ({ className }) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      className={className.size}
    >
      <circle fill={className.fill} cx="12.5" cy="12.5" r="11.98" />
      <path
        fill="#fff"
        d="M17.69,4.41c-.28.46-.53.91-.81,1.34a.53.53,0,0,1-.38.18c-.73,0-1.46,0-2.17,0,.2.51.4,1,.62,1.49,0,.06.17.09.27.09h2.5c-.32.52-.59,1-.89,1.45a.39.39,0,0,1-.3.12H15c-.55,2.63-2.43,3.63-4.92,4L16,20.63c-.86,0-1.63,0-2.41,0a.42.42,0,0,1-.27-.19Q10.65,17,8,13.56a1.2,1.2,0,0,1-.15-.71c0-1,0-1,1-1a4.91,4.91,0,0,0,2.92-.71,2.74,2.74,0,0,0,1.19-2.05H7.28c.33-.54.6-1,.91-1.49.05-.07.23-.1.35-.1h4.15a1.87,1.87,0,0,0-.86-1A4.44,4.44,0,0,0,9.52,6c-.72,0-1.44,0-2.22,0,.31-.52.59-1,.89-1.47a.3.3,0,0,1,.25-.1h9.15Z"
      />
    </svg>
  );
};

export default Rupee;
