import React from "react";

export default function Info({ className, cls1, cls2 }) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 120 120"
      className={className}
    >
      <defs></defs>
      <path
        className={cls1}
        d="M96.92,60.14a36.92,36.92,0,1,1-73.84-.29C23.1,38.51,41.37,22.28,61.71,23,80.8,23.7,96.93,40.11,96.92,60.14ZM60,27.6a32.42,32.42,0,0,0,0,64.84c17.54.06,32.39-14.62,32.4-32A32.47,32.47,0,0,0,60,27.6Z"
      />
      <path
        className={cls1}
        d="M60,27.6A32.47,32.47,0,0,1,92.46,60.39c0,17.43-14.86,32.11-32.4,32.05A32.42,32.42,0,0,1,60,27.6Zm.08,51.34a44.28,44.28,0,0,1,1.07-4.57c2-5.66,4.17-11.27,6.14-16.94,2.1-6.07-.73-9.31-7-8.06-5.21,1.05-8.78,4.54-12,8.48-.5.63-.41,1.73-.6,2.61A9.32,9.32,0,0,0,50,59.26c1.55-1.44,2.92-3.09,4.49-4.51a2.08,2.08,0,0,1,2-.24c.38.26.48,1.34.3,1.94-.41,1.41-1.12,2.74-1.61,4.13-2.18,6.18-4.64,12.28-6.4,18.58-1.25,4.49,1.57,7,6.12,5.89,5.85-1.38,10.24-5,13.77-9.67a9,9,0,0,0,.85-2.57C65.79,74,64.61,78.23,60.1,78.94ZM61,41.31a4.88,4.88,0,0,0,5.4,4.94A6.25,6.25,0,0,0,72.42,40a5.11,5.11,0,0,0-5.22-5A6.19,6.19,0,0,0,61,41.31Z"
      />
      <path
        className={cls2}
        d="M60.1,78.94c4.51-.71,5.69-5,9.37-6.13a9,9,0,0,1-.85,2.57c-3.53,4.7-7.92,8.29-13.77,9.67-4.55,1.08-7.37-1.4-6.12-5.89,1.76-6.3,4.22-12.4,6.4-18.58.49-1.39,1.2-2.72,1.61-4.13.18-.6.08-1.68-.3-1.94a2.08,2.08,0,0,0-2,.24c-1.57,1.42-2.94,3.07-4.49,4.51a9.32,9.32,0,0,1-2.23,1.2c.19-.88.1-2,.6-2.61,3.17-3.94,6.74-7.43,12-8.48,6.27-1.25,9.1,2,7,8.06-2,5.67-4.13,11.28-6.14,16.94A44.28,44.28,0,0,0,60.1,78.94Z"
      />
      <path
        className={cls2}
        d="M61,41.31A6.19,6.19,0,0,1,67.2,35a5.11,5.11,0,0,1,5.22,5,6.25,6.25,0,0,1-6.07,6.27A4.88,4.88,0,0,1,61,41.31Z"
      />
    </svg>
  );
}

{
  /* <style>.cls-1{fill:#e35f14;}.cls-2{fill:#fff;}</style> */
}
