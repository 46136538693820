import React from 'react';

const EventsIcon = ({ className }) => {
  return (
    <svg
      className={className}
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      viewBox='0 0 32 32'
    >
      <title>Events-1</title>
      <path d='M6.329 0.356c-0.341 0.341-0.356 0.384-0.356 2.631v2.304l0.412 0.412c0.412 0.398 0.469 0.412 1.636 0.398 1.067-0.014 1.266-0.057 1.579-0.356 0.356-0.327 0.356-0.341 0.356-2.716 0-1.308-0.057-2.46-0.114-2.546-0.228-0.341-0.782-0.484-1.948-0.484-1.109 0-1.252 0.028-1.564 0.356z'></path>
      <path d='M22.542 0.171c-0.156 0.085-0.327 0.228-0.384 0.313s-0.114 1.237-0.114 2.546v2.389l0.37 0.341c0.341 0.327 0.498 0.356 1.593 0.341 1.138-0.014 1.237-0.043 1.621-0.412l0.398-0.398v-2.19c0-2.503-0.043-2.731-0.512-2.944-0.455-0.199-2.603-0.199-2.972 0.014z'></path>
      <path d='M3.399 4.039c-0.028 0.043-0.228 0.085-0.412 0.128-0.967 0.156-1.892 0.882-2.503 1.991l-0.412 0.74v22.187l0.427 0.754c0.455 0.839 0.939 1.294 1.835 1.778l0.583 0.313 25.941 0.071 0.853-0.441c0.924-0.469 1.394-0.939 1.906-1.892l0.313-0.583v-22.059l-0.427-0.839c-0.711-1.38-2.119-2.204-3.797-2.204h-0.683l-0.014 0.526c-0.014 0.299-0.014 0.626 0 0.711 0.043 0.27-0.341 0.924-0.768 1.323-0.356 0.327-0.498 0.356-2.062 0.398-2.404 0.071-2.987-0.299-3.129-2.034l-0.071-0.853h-9.956l-0.071 0.853c-0.142 1.707-0.711 2.076-3.072 2.048-1.522-0.014-1.664-0.043-2.062-0.37-0.441-0.384-0.868-1.067-0.825-1.365 0.014-0.085 0.014-0.412 0-0.711l-0.014-0.526h-0.754c-0.427 0-0.796 0.028-0.825 0.057zM28.601 10.112c0.128 0.028 0.398 0.185 0.612 0.327 0.796 0.512 0.796 0.484 0.796 9.614 0 8.562 0 8.619-0.54 9.273-0.597 0.711-0.142 0.683-13.497 0.683-12.203 0-12.501-0.014-12.956-0.284-0.256-0.156-0.583-0.484-0.74-0.74-0.27-0.455-0.284-0.754-0.284-8.974 0-9.045 0-8.903 0.683-9.529 0.142-0.142 0.412-0.284 0.597-0.313 0.171-0.028 0.356-0.1 0.398-0.142 0.1-0.1 24.619-0.014 24.932 0.085z'></path>
      <path d='M6.315 14.279c-0.242 0.185-0.27 0.384-0.27 1.721 0 1.92 0 1.92 1.991 1.92 1.892 0 1.92-0.028 1.92-1.948 0-1.863-0.028-1.892-1.934-1.892-1.010 0-1.508 0.057-1.707 0.199z'></path>
      <path d='M14.322 14.336c-0.37 0.398-0.341 3.015 0.014 3.342 0.398 0.37 3.015 0.341 3.342-0.014 0.37-0.398 0.341-3.015-0.014-3.342-0.398-0.37-3.015-0.341-3.342 0.014z'></path>
      <path d='M22.286 14.336c-0.199 0.213-0.242 0.569-0.242 1.692 0 1.863 0.028 1.892 1.934 1.892 0.768 0 1.479-0.057 1.564-0.114 0.37-0.242 0.498-0.796 0.469-1.991-0.043-1.664-0.114-1.735-2.048-1.735-1.223 0-1.479 0.043-1.678 0.256z'></path>
      <path d='M6.272 22.414c-0.256 0.327-0.299 0.583-0.299 1.65 0 1.152 0.028 1.294 0.356 1.607 0.313 0.327 0.455 0.356 1.621 0.356 1.138 0 1.323-0.043 1.678-0.341l0.398-0.341-0.071-1.451c-0.085-1.835-0.114-1.849-2.005-1.849-1.323 0-1.394 0.014-1.678 0.37z'></path>
      <path d='M14.322 22.3c-0.199 0.213-0.242 0.555-0.242 1.678 0 1.508 0.043 1.636 0.526 1.892 0.398 0.199 2.347 0.199 2.802 0 0.469-0.213 0.484-0.299 0.526-1.892 0.028-1.877-0.014-1.934-1.934-1.934-1.223 0-1.479 0.043-1.678 0.256z'></path>
      <path d='M22.329 22.329c-0.242 0.242-0.284 0.469-0.284 1.692 0 1.365 0.014 1.422 0.37 1.707 0.327 0.256 0.583 0.299 1.65 0.299 1.152 0 1.294-0.028 1.607-0.356 0.327-0.313 0.356-0.455 0.356-1.607 0-1.067-0.043-1.323-0.299-1.65-0.284-0.356-0.341-0.37-1.707-0.37-1.223 0-1.451 0.043-1.692 0.284z'></path>
    </svg>
  );
};

export default EventsIcon;
