import React from "react";

export default function Syllabus({ className, cls1 }) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 120 120"
      className={className}
    >
      <defs></defs>
      <path
        className={cls1}
        d="M60,88.59H24.88c-1.9,0-2.46-.55-2.46-2.41V35.38c0-1.63.53-2.08,2.13-2.22,3.72-.34,7.46-.58,11.14-1.21a34,34,0,0,1,19.86,2.52c1.25.55,2.5,1.11,3.72,1.72a1.5,1.5,0,0,0,1.49,0A45.71,45.71,0,0,1,72.44,32a29,29,0,0,1,9.16-.33c4.77.57,9.49,1.54,14.31,1.57A1.64,1.64,0,0,1,97.58,35V86.29c0,1.7-.6,2.29-2.33,2.29H60ZM25.87,36.71V69.13c0,3.24,0,6.48,0,9.72,0,.62.17.89.81.8.19,0,.39,0,.58,0,3.77-.45,7.53-.94,11.3-1.36a29.93,29.93,0,0,1,8.76.3,47.56,47.56,0,0,1,12,4.2,1.4,1.4,0,0,0,1.32,0c2-.87,3.92-1.74,5.93-2.5a33,33,0,0,1,16.55-1.81c3.2.46,6.41.81,9.62,1.19,1.35.16,1.35.14,1.35-1.23q0-10.84,0-21.71,0-9.51,0-19c0-.67-.08-1-.92-1.06-4.18-.45-8.35-1.12-12.54-1.5a31.74,31.74,0,0,0-6.68.14c-4,.51-7.7,2.14-11.34,3.87a1.38,1.38,0,0,0-.89,1.46q0,19.16,0,38.31c0,1.63-.66,2.55-1.8,2.52s-1.76-.91-1.76-2.48q0-19.25,0-38.48a1.15,1.15,0,0,0-.78-1.27c-1.8-.75-3.55-1.62-5.37-2.32a27.58,27.58,0,0,0-14.47-1.58c-2.12.34-4.25.63-6.37.9C29.49,36.36,27.74,36.51,25.87,36.71Zm38.65,48.1,0,.22h28.7c.27,0,.74-.06.76-.16a4,4,0,0,0,0-1.47c0-.14-.5-.26-.78-.29-2.85-.32-5.72-.54-8.56-.95a34,34,0,0,0-12-.08A39.15,39.15,0,0,0,64.52,84.81ZM55.46,85A29.4,29.4,0,0,0,38.1,81.76c-3.82.47-7.64.89-11.46,1.34A1,1,0,1,0,27,85H55.46Z"
      />
      <path
        className={cls1}
        d="M41.06,39.56a35.93,35.93,0,0,1,12.3,2.24,1.74,1.74,0,1,1-1.19,3.26,26.57,26.57,0,0,0-7.37-1.74,60.19,60.19,0,0,0-6.85.1c-2.16.16-4.31.59-6.46.89A1.84,1.84,0,0,1,29.65,43a1.65,1.65,0,0,1,1.17-2.1c1.22-.27,2.47-.43,3.71-.59C37,40,39.51,39.74,41.06,39.56Z"
      />
      <path
        className={cls1}
        d="M90.38,42.52a1.74,1.74,0,0,1-2.12,1.73c-3.82-.5-7.6-1.25-11.49-1.09A29.53,29.53,0,0,0,68.15,45,1.89,1.89,0,0,1,66,44.7a1.65,1.65,0,0,1,.52-2.81,73.43,73.43,0,0,1,7.41-2c4.29-.83,8.56-.08,12.81.58.8.12,1.61.2,2.39.38A1.55,1.55,0,0,1,90.38,42.52Z"
      />
      <path
        className={cls1}
        d="M41.26,58.21a34.49,34.49,0,0,1,12.09,2.22,1.72,1.72,0,0,1,1.13,2.2,1.75,1.75,0,0,1-2.3,1.08c-1.77-.5-3.52-1.07-5.3-1.48A25.46,25.46,0,0,0,38,62c-2.15.28-4.3.62-6.45.9a1.79,1.79,0,0,1-1.87-1.35,1.62,1.62,0,0,1,1.25-2c1.69-.33,3.4-.61,5.11-.82C38,58.48,40,58.33,41.26,58.21Z"
      />
      <path
        className={cls1}
        d="M78,61.77a27.83,27.83,0,0,0-9.72,1.78,3.15,3.15,0,0,1-1.77.09,1.46,1.46,0,0,1-1-1.54,1.57,1.57,0,0,1,1.11-1.61C69,59.8,71.45,59,74,58.55c4.24-.78,8.45-.09,12.64.59.8.12,1.61.19,2.4.36a1.67,1.67,0,0,1,1.35,2.13,1.87,1.87,0,0,1-2.15,1.26c-3.4-.46-6.8-.88-10.2-1.32Z"
      />
      <path
        className={cls1}
        d="M88.33,72.19c-1.73-.23-3.62-.43-5.49-.74a28.2,28.2,0,0,0-14.25,1.3,4.08,4.08,0,0,1-1.84.3,1.52,1.52,0,0,1-1.32-1.6,1.58,1.58,0,0,1,1.15-1.67c2.26-.66,4.52-1.39,6.84-1.84a32.19,32.19,0,0,1,11.41.25c1.29.21,2.59.38,3.88.56a1.72,1.72,0,0,1,1.67,1.9C90.32,71.65,89.62,72.22,88.33,72.19Z"
      />
      <path
        className={cls1}
        d="M41.92,67.61a30.35,30.35,0,0,1,11.34,2.12,1.7,1.7,0,0,1,1.22,2.21A1.8,1.8,0,0,1,52.11,73a58.65,58.65,0,0,0-7-1.7,32,32,0,0,0-6.42,0c-2.36.17-4.7.62-7,.89-1.22.13-2-.45-2.06-1.43-.11-1.14.51-1.83,1.74-2,2.79-.37,5.58-.77,8.37-1.12A17.6,17.6,0,0,1,41.92,67.61Z"
      />
      <path
        className={cls1}
        d="M41.92,49a31.39,31.39,0,0,1,11.42,2.17,1.7,1.7,0,0,1,1.15,2.19A1.75,1.75,0,0,1,52.2,54.4a58.65,58.65,0,0,0-7-1.7,33.88,33.88,0,0,0-6.68,0c-2.22.15-4.41.6-6.63.86-1.52.19-2.36-.49-2.32-1.77A1.61,1.61,0,0,1,31,50.17c3.64-.5,7.29-1,10.94-1.44Z"
      />
      <path
        className={cls1}
        d="M78.21,48.77l8.88,1.11c.58.07,1.16.16,1.74.25a1.68,1.68,0,0,1,1.54,1.92,1.55,1.55,0,0,1-1.76,1.5A43.15,43.15,0,0,1,84.22,53,29.39,29.39,0,0,0,68.4,54.19a3.55,3.55,0,0,1-1.53.28A1.64,1.64,0,0,1,65.43,53a1.63,1.63,0,0,1,1.18-1.81,57.24,57.24,0,0,1,5.68-1.62C74.17,49.13,76.1,49,78.21,48.77Z"
      />
    </svg>
  );
}

{
  /* <style>.cls-1{fill:#e35f14;}</style> */
}
