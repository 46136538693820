import React from 'react';

const ReportIcon = ({ className }) => {
  return (
    <svg
      className={className}
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      viewBox='0 0 32 32'
    >
      <title>Reports-1</title>
      <path d='M23.907 1.808c-0.129 0.115-0.23 0.402-0.23 0.631 0 0.761 0.258 0.861 2.325 0.861 1.105 0 1.837 0.057 1.808 0.129-0.072 0.215-7.591 8.768-7.691 8.768-0.057 0-1.779-1.363-3.831-3.013-2.64-2.138-3.831-3.013-4.090-3.013-0.287 0-1.363 0.933-4.735 4.118-6.228 5.883-5.883 5.525-5.883 5.97 0 0.402 0.445 0.961 0.761 0.961 0.373-0.014 0.933-0.502 5.266-4.592 2.468-2.325 4.535-4.248 4.606-4.276 0.057-0.029 1.808 1.306 3.889 2.97 2.827 2.282 3.86 3.028 4.147 3.028 0.445 0 0.789-0.373 5.869-6.156l2.942-3.358 0.072 1.822c0.057 1.593 0.1 1.851 0.344 2.038 0.373 0.258 0.66 0.258 1.062-0.029 0.316-0.215 0.316-0.273 0.316-3.43 0-2.726-0.029-3.243-0.23-3.43-0.33-0.344-6.386-0.344-6.716 0z'></path>
      <path d='M25.744 12.829c-0.258 0.201-0.273 0.474-0.301 7.864-0.029 5.984 0 7.72 0.143 8.007 0.187 0.359 0.187 0.359 2.497 0.402 1.966 0.029 2.353 0 2.54-0.187 0.201-0.215 0.23-1.091 0.23-8.022 0-7.361-0.014-7.806-0.258-8.022-0.23-0.201-0.617-0.244-2.411-0.244-1.636 0-2.224 0.043-2.439 0.201z'></path>
      <path d='M9.83 15.713l-0.359 0.344v6.142c0 4.664 0.043 6.228 0.187 6.5 0.187 0.359 0.187 0.359 2.497 0.402 1.966 0.029 2.353 0 2.54-0.187 0.201-0.215 0.23-0.976 0.23-6.673v-6.443l-0.316-0.215c-0.258-0.187-0.746-0.23-2.382-0.23-1.98 0-2.066 0.014-2.396 0.359z'></path>
      <path d='M17.736 18.454c-0.201 0.187-0.23 0.861-0.23 5.209 0 4.649 0.014 5.008 0.258 5.223 0.23 0.201 0.617 0.244 2.511 0.244 2.152 0 2.239-0.014 2.454-0.316 0.201-0.273 0.23-1.004 0.23-5.137s-0.029-4.865-0.23-5.137c-0.215-0.301-0.301-0.316-2.497-0.316-1.88 0-2.31 0.043-2.497 0.23z'></path>
      <path d='M1.822 21.209c-0.215 0.23-0.244 0.718-0.244 3.86 0 3.286 0.014 3.602 0.258 3.817 0.23 0.201 0.617 0.244 2.511 0.244 2.152 0 2.239-0.014 2.454-0.316 0.187-0.273 0.23-0.89 0.23-3.803 0-4.42 0.244-4.061-2.769-4.061-1.952 0-2.239 0.029-2.439 0.258z'></path>
    </svg>
  );
};

export default ReportIcon;
