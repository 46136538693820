import React from 'react';

const Approved = ({ className, fill }) => {
  return (
    <svg 
    className={className} 
    version="1.0"
    
     xmlns="http://www.w3.org/2000/svg"
    width="225.000000pt" 
    height="225.000000pt" 
    viewBox="0 0 225.000000 225.000000"
    preserveAspectRatio="xMidYMid meet">
   
   <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
    stroke="none">
   <path d="M928 2050 c-184 -39 -325 -117 -468 -260 -110 -110 -149 -167 -207
   -300 -117 -272 -92 -594 69 -868 43 -73 227 -257 300 -300 322 -189 684 -189
   1006 0 73 43 257 227 300 300 189 322 189 684 0 1006 -43 73 -227 257 -300
   300 -217 128 -467 171 -700 122z m842 -429 c44 -35 66 -92 56 -146 -7 -36 -50
   -83 -409 -444 -428 -429 -451 -448 -524 -435 -32 5 -68 37 -241 208 -112 110
   -210 213 -218 228 -46 87 33 208 135 208 48 0 99 -32 175 -109 38 -38 74 -67
   79 -64 6 3 7 2 4 -4 -3 -5 16 -32 44 -59 l49 -49 343 343 c221 221 353 346
   372 352 44 13 95 2 135 -29z"/>
   </g>
   </svg>
   
  )
}
export default Approved;