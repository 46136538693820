import React from "react";

const PdfIcon = ({ className, fill }) => {
  return (
    <svg
      className={className}
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="30.000000pt"
      height="32.000000pt"
      viewBox="0 0 120.000000 120.000000"
    >
      <g
        transform="translate(0.000000,120.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M612 870 l406 -5 27 -25 c19 -18 24 -20 19 -7 -17 45 -39 47 -461 45
l-398 -3 407 -5z"
        />
        <path
          d="M161 844 c-12 -15 -21 -32 -21 -38 0 -6 9 3 19 20 11 18 25 34 33 37
8 3 9 6 1 6 -6 1 -21 -11 -32 -25z"
        />
        <path
          d="M1074 585 c0 -126 2 -177 3 -112 2 65 2 168 0 230 -1 62 -3 9 -3
-118z"
        />
        <path
          d="M270 585 c0 -97 1 -105 19 -105 16 0 20 8 23 37 3 35 6 38 40 44 50
8 68 25 68 64 0 47 -26 65 -93 65 l-57 0 0 -105z m107 59 c8 -22 -9 -48 -36
-52 -18 -3 -21 1 -21 32 0 32 3 36 25 36 15 0 28 -7 32 -16z"
        />
        <path
          d="M524 668 c3 -13 6 -61 6 -107 l0 -83 66 4 c85 5 114 33 114 106 -1
77 -33 102 -131 102 -59 0 -61 -1 -55 -22z m112 -16 c16 -11 34 -48 34 -72 0
-29 -44 -70 -75 -70 -25 0 -25 1 -25 75 l0 75 28 0 c15 0 32 -4 38 -8z"
        />
        <path
          d="M820 585 c0 -98 1 -105 20 -105 17 0 20 7 20 45 l0 45 40 0 c29 0 40
4 40 15 0 11 -11 15 -40 15 -38 0 -40 2 -40 30 0 29 2 30 45 30 33 0 45 4 45
15 0 12 -14 15 -65 15 l-65 0 0 -105z"
        />
        <path
          d="M140 364 c0 -17 40 -64 53 -63 8 0 7 3 -1 6 -8 3 -22 19 -33 37 -10
17 -19 26 -19 20z"
        />
        <path
          d="M1042 328 c-22 -23 -24 -23 -430 -28 l-407 -5 398 -3 c418 -2 444 0
460 43 8 20 6 19 -21 -7z"
        />
      </g>
    </svg>
  );
};

export default PdfIcon;
